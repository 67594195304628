<template>
    <div class="w-full text-sm pl-4 pt-3 pr-6 mt-3 m-auto max-w-6xl overflow-auto" style="background:#edf2f7d6">
      <div 
        class="resizing-video w-full pb-5 md:mr-5 md:mb-1 md:w-1/2 md:float-left relative" 
      >
        <iframe 
          src="https://player.vimeo.com/video/447280336/" 
          class="absolute t-0 l-0 w-full h-full"
          frameborder="0" 
          allow="autoplay; fullscreen" allowfullscreen>
        </iframe>
      </div>
      <h1 class="pt-5 md:pt-0 text-3xl font-semibold text-secondary text-left leading-8 pb-3 xl:mt-0">Слово главного редактора</h1>
      <p class="text-left pb-3">
        Сегодня жизнь доказала, как много значит для каждого из нас квалификация врача и уровень его образования. Это осознает и медицинское сообщество, которое решило сплотиться в проекте «МедПрактика Образование».
      </p>
      <p class="text-left">
        Вам необходимо посоветоваться с коллегами? Проконсультироваться по вопросам диагностики или лечению того или иного заболевания? Вы готовы поделиться опытом в решении конкретной  клинической задачи? Вам важно узнать, с какими проблемами сталкиваются врачи за рубежом? Добро пожаловать на проект «МедПрактика Образование»! В коротких видеороликах вы получите консультации ведущих экспертов страны и мира.
      </p>
      <div v-if="fullText">
        <p class="text-left py-3">
          Платформа «МедПрактика Образование» предлагает просмотр видеолекций, интерактивный разбор клинических случаев, возможность обсудить свою клиническую задачу с коллегами, лёгкий и удобный поиск статей по интересующей вас теме и многое другое.
        </p>
        <p class="text-left pb-3">
          Один из образовательных разделов платформы предусмотрен для тех, кто просто интересуется своим здоровьем, его сохранением и укреплением. Мы не стали обходить своим вниманием пациентов, поэтому один из разделов Платформы предназначен именно для них. В этом разделе помимо видеолекций, платформа даёт возможность на регулярной основе в режиме онлайн встречаться с высокопрофессиональными клиницистами разных специальностей, получать ответы на свои вопросы, а также самую актуальную информацию по разным заболеваниям, которую дают ТОЛЬКО ПРОФЕССИОНАЛЫ!
        </p>
        <p class="text-left pb-3">
          Дорогие коллеги! Мы очень надеемся, что вы заходите делиться своим опытом – это самое важное, что есть в нашей работе, а эксперты «МедПрактика Образование» обогатят вас своими знаниями! Подписывайтесь на обновления «МедПрактика Образование»! Будьте в курсе самой последней актуальной информации!
        </p>
      </div>
      <div @click="fullText=!fullText" class="text-left underline pt-2 pr-5 cursor-pointer hover:text-primary-500">
        <p v-if="!fullText">Читать далее ...</p>
        <p v-if="fullText">Свернуть</p>
      </div>
      <p class="text-right pb-6">кандидат медицинских наук<br><span class="text-md font-semibold">Линник Сергей Александрович</span></p>
    </div>
</template>

<script>
export default {
  name: 'About',
  data: function(){
    return {
      fullText: false,
    }
  }
}
</script>

<style scoped>
.resizing-video{
  padding-bottom:56.25%
}
@media screen and (min-width: 768px){
.resizing-video{
  padding-bottom:28.12%
}
}
</style>
