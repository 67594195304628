<template>
  <div  
    class="cursor-pointer block mr-2 mt-3 rounded-md py-2 text-gray-200 border-white-600 
          font-semibold border-2 hover:text-white hover:border-primary-400"
  >
    {{ title }}
  </div>
</template>

<script>

export default {
  name: 'OutlinedButton',
  props: {
    title: String,
  }
}
</script>

<style>
</style>
