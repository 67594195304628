<template>
  <div class="flex px-2 md:px-4 py-8 sm:w-2/3 md:1/2 max-h-full">
    <div class="bg-white rounded-lg shadow-md relative overflow-y-auto scrollbar-style scrolling-touch p-8">
      <p 
        @click="closeClick()"
        class="absolute right-0 top-0 text-3xl text-right text-gray-600 hover:text-primary-500 pr-6 pt-4 cursor-pointer"><font-awesome-icon icon="times"/>
      </p>  
      <div class="text-left rounded-lg border-secondary font-bold text-secondary pb-2 pt-4">{{ date }}</div>
      <p class="text-left news-p" v-html="text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsModal',
  props: {
    text: String,
    date: String
  },
  methods:{
    closeClick() {
      this.$xmodal.close()
    }
  }
}
</script>

<style>
.scrollbar-style::-webkit-scrollbar {
    width: 0.5rem;
}
 
/* Track */
.scrollbar-style::-webkit-scrollbar-track {
    background: #e2e8f0;
}
 
/* Handle */
.scrollbar-style::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #718096;; 
}
</style>
