<template>
  <div class="w-full px-4 pt-3 m-auto max-w-6xl mb-10 bg-secondary py-8 bg-no-repeat bg-top"
        :style="medBg">
    <h1 class="text-3xl font-semibold text-white text-center pb-5 mb-5">Научное общество<br>"Медицинская практика"</h1>
    <div class="text-white sm:px-2 md:px-12 mx-auto text-lg text-left">
      <p class="text-xl font-semibold pb-5">Дорогие друзья!</p>
      <p class="pb-5 md:text-justify">Современное общество бросает вызов каждому и требует предельной скорости понимания и обработки информации, постоянного повышения личной эффективности и непрерывного образования. Особенно это касается врачей, от квалификации и эффективности работы которых зависит здоровье человека.</p>
      <p class="pb-5 md:text-justify">Мы создаем для врачей вдохновляющую плодотворную среду для роста, как профессионального, так и личностного.</p>
      <p class="pb-5 md:text-justify">Мы предлагаем удобный современный формат восприятия информации оказываем помощь в понимании современных достижений мировой медицины в рамках образовательных программ. Наша деятельность направлена на то, чтобы теоретические знания переносились в реальную практику. Именно поэтому мы называем себя «Медицинская практика».</p>
      <p class="pb-5 md:text-justify">Современная тенденция непрерывного образования близка нашей команде. Мы постоянно совершенствуемся, ищем новые возможности для применения своих навыков и получения знаний. Мы верим, что, только инвестируя в знания, можно добиться успеха в своей деятельности, в отношениях с партнерами и медицинским сообществом и в устойчивом развитии компании.</p>
      <p class="pb-5">Мы всегда рады новым проектам и открыты для обсуждения и компромиссов.</p>
      <!--<p class="pb-5">Подписывайтесь на наши каналы в 
        <span class="text-3xl">
          <a href="https://www.youtube.com/channel/UC_4NQrJEw8SDMdmTIBPCk6A" alt="Youtube" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'youtube-square']" />
          </a>
          <a href="https://www.instagram.com/_medicalpractice/" alt="Instagram" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.facebook.com/medicalpractice.ru" alt="Facebook" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a href="https://vk.com/mmedpractica" alt="Вконтакте" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'vk']" />
          </a>
          <a href="https://ok.ru/group/63361263140906" alt="Одноклассники" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'odnoklassniki']" />
          </a>
        </span>!
      </p>-->
      <p class="pt-5 pb-5 text-center">Подписывайтесь на наши каналы!<br>
        <span class="text-4xl">
          <a href="https://www.youtube.com/channel/UC_4NQrJEw8SDMdmTIBPCk6A" alt="Youtube" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'youtube-square']" />
          </a>
          <a href="https://www.instagram.com/_medicalpractice/" alt="Instagram" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://www.facebook.com/medicalpractice.ru" alt="Facebook" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a href="https://vk.com/mmedpractica" alt="Вконтакте" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'vk']" />
          </a>
          <a href="https://ok.ru/group/63361263140906" alt="Одноклассники" target="_blank" class="p-2">
            <font-awesome-icon :icon="['fab', 'odnoklassniki']" />
          </a>
        </span>
      </p>
      <a
        href="https://medicalpractice.ru/"
        target="_blank"
        class="cursor-pointer mt-2 block p-2 border border-white text-white text-md font-semibold
        w-56 ml-auto mr-auto text-center"
      >
        Подробнее
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MedPractik',
  data: function () {
    return {
      medBg:{
        backgroundImage: `url(${require('@/assets/img/bg_landing2.png')})`,
        backgroundSize: `15%`,
        backgroundPositionX: `95%`,
        zIndex: `-10`
      }
    }
  }
}
</script>

<style scoped>
</style>
