<template>
  <div
    class="w-full px-4 pb-10 pt-3 mt-3 m-auto max-w-6xl bg-white bg-no-repeat bg-bottom"
    :style="newsBg"
  >
    <h1 class="text-3xl font-semibold text-primary-500 text-center my-5">Новости</h1>

    <div class="flex flex-no-wrap">
      <font-awesome-icon 
        @click="prev"
        icon="chevron-left" 
        class="text-3xl mr-2 self-center -mt-20"
        :class="{'cursor-pointer text-secondary hover:text-primary-500': prevAvailable,
          'text-gray-500': !prevAvailable}"
      />
      <div class="flex">
        <div 
          v-for="news in showList"
          v-bind:key="news.id"
          @click="showExpertModal(news.id)"
          class="w-full md:w-1/2 lg:w-1/3 px-6 pb-6 cursor-pointer"
        >
          <div class="text-left rounded-lg border-secondary font-bold text-secondary pb-2">
            {{ news.date }}
          </div>
          <p class="text-justify news-p" v-html="news.shortText"></p>          
        </div>
      </div>
      <font-awesome-icon 
        @click="next"
        icon="chevron-right" 
        class="text-3xl mr-2 self-center -mt-20"
        :class="{'cursor-pointer text-secondary hover:text-primary-500': nextAvailable,
          'text-gray-500': !nextAvailable}"
      />
    </div>
  </div>
</template>

<script>
import NewsModal from '@/components/landing/NewsModal.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'News',
  data: function() {
    return {
      width: null,
      currentNews: 0,
      nextAvailable: false,
      prevAvailable: false,
      newsBg: {
        backgroundImage: `url(${require('@/assets/img/bg_landing1.png')})`,
        backgroundSize: `15%`,
        backgroundPositionX: `95%`,
        zIndex: `-10`
      }
    }
  },
  computed: {
    ...mapState({
      allNewsList: state => state.news.news
    }),
    newsList() {
      this.allNewsList.forEach(function(news, i, arr) {
        var to = news.text.search('\\[read-more\\]')
        if (to == -1)
          news.shortText = news.text.substring(0, news.text.length)
        else
        {
          news.shortText = news.text.substring(0, to)
          news.shortText += '<br><span class="hover:text-primary-300 text-primary-500 pt-2 text-right block">[ Читать дальше... ]</span>';
          news.text = news.text.replace("\[read-more\]", '')
        }
      })

      return this.allNewsList
    },
    cnt() {
      return this.newsList.length
    },
    newsOnScreen() {
      if (this.width > 900) return 3
      if (this.width < 640) return 1
      return 2
    },
    showList() {
      return this.newsList.slice(this.currentNews, this.currentNews + this.newsOnScreen)
    },
  },
  methods: {
    ...mapActions({
      getNews: 'news/getNews'
    }),
    showExpertModal(newsid) {
      let opennews = this.allNewsList.find(x => x.id === newsid);
      this.$xmodal.open({
        component: require("@/components/landing/NewsModal.vue").default,
        backgroundColor: '#aaaaaa',
        opacity: '0.5',
        width:'50%',
        props: {
          text: opennews.text,
          date: opennews.date
        }
      })
    },
    updateWidth() {
      this.width = window.innerWidth;
    },
    next() {
      if (this.nextAvailable) {
        this.currentNews += this.newsOnScreen
        if (this.currentNews + this.newsOnScreen == this.newsList.length)
          this.nextAvailable = false
        this.prevAvailable = true
      }
    },
    prev() {
      if (this.prevAvailable) {
        this.currentNews -= this.newsOnScreen
        if (this.currentNews == 0)
          this.prevAvailable = false
        this.nextAvailable = true
      }
    }
  },
  created() {
    this.getNews().then(() => {
      if (this.newsList.length > this.newsOnScreen) 
        this.nextAvailable = true
    })
    window.addEventListener('resize', this.updateWidth)
    this.updateWidth()
  }
}
</script>

<style>
.news-p a {
  text-decoration: underline !important;
  color: #5b2d87 !important;
}

.news-p a:hover {
  color: #00afd3 !important;
}
</style>
