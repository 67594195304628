<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in" @after-enter="scrollIfNeeded()">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Page content -->
    <div v-if="!isLoading" key="content">
      <About />
      <TherapyAreas :therapyAreas="therapyAreas" />
      <News />
      <MedPractik id="practice" />
      <Partners id="partners"/>
      <Contacts id="contacts" />
    </div>

  </fade-transition>
</template>

<script>
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import { FadeTransition } from 'vue2-transitions'

import { apiUrl, axios } from '@/store/api_config'
const therapyareasUrl = 'api/therapyareas/'

import TherapyAreas from '@/components/landing/TherapyAreas.vue'
import About from '@/components/landing/About.vue'
import Partners from '@/components/landing/Partners.vue'
import News from '@/components/landing/News.vue'
import MedPractik from '@/components/landing/MedPractik.vue'
import Contacts from '@/components/landing/Contacts.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Main',
  components: {
    LoadingScreen,
    FadeTransition,
    TherapyAreas,
    About,
    Partners,
    News,
    MedPractik,
    Contacts,
    Footer
  },
  data: function() {
    return {
      isLoading: true,
      therapyAreas: []
    }
  },
  methods: {
    scrollIfNeeded() {
      if (this.$route.hash) {
        this.$scrollTo(this.$route.hash, { duration: 900, easing: "ease", /* offset: 0 */ })
      }
    }
  },
  created: function () {
    axios.get(apiUrl + therapyareasUrl)
      .then(response => {
        this.therapyAreas = response.data
        this.isLoading = false
      })
      .catch(error => {})
  }
}
</script>