import axios from 'axios'

// Should be disabled in production
// Allows client receive credentials in CORS
axios.defaults.withCredentials = true;

// http://domainname.com/videoportal/
const addFolder = process.env.BASE_URL;

const apiUrl = window.location.protocol + '//' + window.location.hostname + addFolder;

export {axios, apiUrl}