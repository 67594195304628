<template>
  <div class="w-full pt-5 px-1 md:px-3 flex flex-wrap items-center m-auto max-w-6xl">
    <TherapyAreaCard
      v-for="therapyarea in therapyAreas"
      :key="therapyarea.id" 
      :imgLink="therapyarea.url_name"
      :blocked="!therapyarea.isAvailable"
      :url="therapyarea.url_name"
    />
  </div>
</template>

<script>
import TherapyAreaCard from '@/components/landing/TherapyAreaCard.vue'

export default {
  name: 'TherapyAreas',
  components: {
    TherapyAreaCard
  },
  props: {
    therapyAreas: Array
  }
}
</script>

<style>
</style>
