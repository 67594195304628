<template>
  <div class="w-full max-w-6xl mx-auto px-2 sm:px-6 lg:px-8 mt-8">
    <h1 class="text-3xl font-semibold text-secondary text-center pb-5 mb-5">Партнеры</h1>
    <div class="flex flex-wrap h-50 justify-center items-center">
    <a 
      v-for="partner in partners"
      :key="partner.id"
      :href="partner.link" 
      class="block w-full sm:w-1/2 lg:w-1/3 pb-8 hover:underline hover:text-secondary px-5"
      :alt="partner.title"
      :title="partner.title"       
      target="_blank"
      >
      <div :class="partner.padding">
        <img :src="require('@/assets/img/partners/' + partner.id + '.png')" class="pb-2"/>
      </div>
      <p v-if="partner.showTitle">{{partner.title}}</p>
    </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partners',
  data: function(){
    return{
      partners:[
        {
          id:10,
          title:"ГБУЗ МО МОНИКИ им. М. Ф. Владимирского",
          link:"https://www.monikiweb.ru/",
          showTitle: false,
          padding:"px-16"
        },
        {
          id:1,
          title:"Международный союз помощи и поддержки пациентов",
          link:"https://www.ip-u.ru/",
          showTitle: false,
          padding:"px-8"
        },
        {
          id:2,
          title:"ГБУЗ Волгоградский областной клинический онкологический диспансер",
          link:"https://vokod34.ru",
          showTitle: true,
          padding:"px-24"
        },
        {
          id:3,
          title:"«Ракфонд»",
          link:"https://www.rakfond.org/",
          showTitle: false,
          padding:"px-8"
        },
        {
          id:4,
          title:"ГБУЗ Самарский областной клинический онкологический диспансер",
          link:"https://samaraonko.ru",
          showTitle: true,
          padding:"px-24"
        },
        {
          id:6,
          title:"Российское общество рентгенологов и радиологов",
          link:"https://russian-radiology.ru/",
          showTitle: false,
          padding:"px-0"
        },
        {
          id:5,
          title:"ГБУЗ «Оренбургский областной клинический онкологический диспансер»",
          link:"http://ookod.ru/",
          showTitle: false,
          padding:"px-16"
        },
        {
          id:7,
          title:"ГБУЗ «ВОЛОГОДСКИЙ ОБЛАСТНОЙ ОНКОЛОГИЧЕСКИЙ ДИСПАНСЕР»",
          link:"http://onko35.ru/",
          showTitle: false,
          padding:"px-24"
        },
        {
          id:8,
          title:"ГБУЗ «Челябинский областной клинический центр онкологии и ядерной медицины»",
          link:"http://www.chelonco.ru/new/",
          showTitle: true,
          padding:"px-24"
        },
        {
          id:9,
          title:"Национальный институт медицинского права",
          link:"http://www.med-law.ru/",
          showTitle: true,
          padding:"px-24"
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>
