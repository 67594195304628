<template>
  <div
    class="w-full mx-auto px-2 sm:px-6 lg:px-8 mt-5 py-8 bg-secondary"
    style="background: linear-gradient(-45deg, #01afd4, #5b2d87);"
  >
    <h1 class="text-3xl font-semibold text-white text-center pb-4 mt-5 md:mt-0">Контакты</h1>
    <div class="text-xl text-white pb-4 flex justify-center">
      <p>
        <font-awesome-icon icon="envelope-open-text" class="mr-2"/>
        Электронная почта:</p>
      <img src="@/assets/img/light-email.png">
    </div>
    <button
      @click="writeClick()"
      class="cursor-pointer block mr-2 mt-3 rounded-md py-2 text-gray-200 border-white-600
        font-semibold border-2 hover:text-white hover:border-primary-400 w-1/3 mr-auto ml-auto mb-5"
    >
      Написать нам
    </button>
    <SendMessage 
      v-if="writeOpen"
      class="md:w-2/3 lg:w-1/2 m-auto"
    /> 
    <div class="text-white pt-10">
    © Медицинская практика 2021
    </div>
  </div>
</template>

<script>
import OutlinedButton from '@/components/_partials/OutlinedButton.vue'
import SendMessage from '@/components/SendMessage.vue'

export default {
  name: 'Contacts',
  components: {
    OutlinedButton,
    SendMessage
  },
  data: function() {
    return {
      writeOpen: false
    }
  },
  methods: {
    writeClick() {
      this.$xmodal.open({
        component: require("@/components/SendMessage.vue").default,
        backgroundColor: '#aaaaaa',
        opacity: '0.5'
      })
    }
  }
}
</script>

<style>
</style>
