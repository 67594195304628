<template>
<div class="bg-grey-300 py-4">
© Медицинская практика 2021<!--<br>
Контакты | Партнеры | Эксперты-->
</div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
