<template>
  <div class="w-1/2 sm:w-1/3 md:w-1/4 p-1 h-auto relative">
    <router-link v-if="!blocked" :to="url">
      <img 
        :src="require(`@/assets/img/${imgLink}.png`)" 
        class="rounded-lg shadow-md bg-gray-500"
        />       
    </router-link>

    <div v-if="blocked">
      <img 
        :src="require(`@/assets/img/${imgLink}.png`)" 
        class="rounded-lg shadow-md bg-gray-500 opacity-75"
        />
      <div
        v-if="blocked"
        class="block w-full absolute text-white top-0 left-0 pt-3 text-center">
        В разработке <font-awesome-icon icon="lock"/>
      </div> 
    </div>

  </div>
</template>

<script>
export default {
  name: 'TherapyAreaCard',
  props: {
    imgLink: String,
    url: String,
    blocked: Boolean
  }
}
</script>