<template>
  <div class="vld-parent w-1/3 bg-white rounded-md p-5 shadow-lg">

    <!-- Loading animation -->
    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0.7"
    />
    <p class="text-left pb-2">Имя:</p>
    <input
      v-model="$v.name.$model"
      class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
        text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
        sm:leading-5 focus:border-primary-500 mb-4 opacity-75 focus:opacity-100"
      placeholder="Имя"
    />
    <p class="text-left pb-2">E-mail:</p>
    <input
      v-model="$v.email.$model"
      class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500
        text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
        sm:leading-5 focus:border-primary-500 mb-4 opacity-75 focus:opacity-100"
      placeholder="e-mail"
    />
    <p class="text-left pb-2">Сообщение:</p>
    <textarea
      v-model="$v.message.$model"
      class="resize-none w-full h-40 py-2 px-3 min-h-30 border border-gray-300 placeholder-gray-500 
        text-gray-700 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-primary-500
        opacity-75 focus:opacity-100"
      placeholder="Сообщение"
    >
    </textarea>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      :sitekey="'6Le9nfwUAAAAAF2tc1FbbNoq4ft9BU9vySq-xZxf'"
      @verify="sendMessage"
      @expired="onCaptchaExpired"
    />

    <div
      @click="validate()"
      class="cursor-pointer block mr-2 mt-3 rounded-md py-2 font-semibold border-2 text-gray-200 
            hover:text-white bg-primary-600 border-primary-600  hover:bg-primary-400 hover:border-primary-400"
    >
      Отправить
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, maxLength } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueRecaptcha from 'vue-recaptcha'
import { mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],
  name: 'SendMessage',
  components: { Loading, VueRecaptcha },
  data: function() {
    return {
      name: null,
      email: null,
      message: null,
      isLoading: false,
      $script: null
    }
  },
  validations: {
    name: {
      required,
      maxLength: maxLength(30)
    },
    email: {
      required,
      email
    },
    message: {
      required,
      maxLength: maxLength(500)
    }
  },
  computed: {
    ...mapGetters({
      isUserActive: 'auth/isUserActive'
    })
  },
  mounted: function() {
    this.$script = document.createElement('script')
    this.$script.async = true
    this.$script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    document.head.appendChild(this.$script)
  },
  beforeDestroy: function() {
    document.head.removeChild(this.$script)
  },
  methods: {
    sendMessage(captchaToken) {
      if (!this.$v.$invalid) {
        const data = {
          name: this.name,
          email: this.email,
          message: this.message,
          captcha: captchaToken
        }
        this.isLoading = true
        this.$store.dispatch('sendMessage', data)
          .then(() => {
            this.isLoading = false
            this.$xmodal.close()
          })
          .catch((error) => {

          })
      }
    },
    validate() {
      if (!this.$v.$invalid) {
        this.$refs.recaptcha.execute()
      }
    },
    onCaptchaLoad() {
      console.log('loaded')
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>

<style>
</style>
