<template>
  <div
    :class="'vld-parent'"
    :style="{height : '80vh'}"
  >
    <loading
      :active="true"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0"
    />
  </div> 
</template>

<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'LoadingScreen',
  components: {
    Loading
  }
}
</script>

<style>
</style>
